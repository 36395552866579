import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { TechRoleEnum } from "@veris-health/user-ms/lib/v1";
import { Routes } from "../routes-config";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectIsLoggedIn } from "../features/shared/slices/authSlice";
import { useAuthenticatedUserRoles, useProfile } from "../context/profile";
import { VrsAppContainer } from "../features/AppContainer/AppContainer";

interface PrivateRouteProps {
  children: React.ReactNode;
  roles?: TechRoleEnum[];
}

const VrsPrivateRoute = ({ children, roles = [] }: PrivateRouteProps): JSX.Element => {
  const profile = useProfile();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const authenticatedUserRoles = useAuthenticatedUserRoles();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(Routes.STAFF_LOGIN);
    }
  }, [isLoggedIn]);

  if (!profile) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const hasAccess = roles.some((roleName) => authenticatedUserRoles.includes(roleName));

  if (hasAccess) {
    return <VrsAppContainer>{children}</VrsAppContainer>;
  }

  if (authenticatedUserRoles.includes(TechRoleEnum.Billingexpert)) {
    return <Navigate to={Routes.BILLING_REPORTS} />;
  }
  if (
    authenticatedUserRoles.includes(TechRoleEnum.Implantmanufacturer) ||
    authenticatedUserRoles.includes(TechRoleEnum.Superadmin)
  ) {
    return <Navigate to={Routes.IMPLANT} />;
  }
  return <Navigate to={Routes.DASHBOARD} />;
};

export default VrsPrivateRoute;
